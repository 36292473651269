<template>
  <div class="lu_novel_pc">
    <div class="first_box content">
      <div class="left">
        <div class="top_icon">
          <cjImg class="img_box" :src="$getImgUrl(imgAddr, appInfo.logoUrl)" />
          <div>{{ appInfo.appName }}</div>
        </div>
        <div class="font_title">品质应用 尽在盒子</div>
        <div class="desTitle">Quality application are all there</div>
        <div style="display: flex; align-items: center">
          <div class="qrcode_box">
            <div class="ewm" id="qrcode"></div>
          </div>
          <div class="button_top_right" v-if="loadFinished">
            <div class="guankan" @click="toApp()">在线观看</div>
          </div>
        </div>
      </div>
      <div class="right" style="text-align: right">
        <img class="img_style" src="../../../assets/pic/pic1.png" />
      </div>
    </div>
    <div class="secondBox content">
      <div class="left" style="text-align: left">
        <img class="img_style" src="../../../assets/pic/pic2.png" alt="" />
      </div>
      <div class="right">
        <div class="font_title" style="font-size: 3.6vw; letter-spacing: 0.1vw">海量资源 精彩纷呈</div>
        <div class="titleDes">Massive resources exciting and diverse</div>
        <div class="detailDes">福利盒子与各大运营商合作，同时持续扶持优质的内容，涵盖了小说、漫画、影视、视频等多类优秀福利app。</div>
      </div>
    </div>
    <div class="thirdBox content">
      <div class="right">
        <div class="font_title" style="letter-spacing: 0.1vw">关于我们</div>
        <div class="titleDes">About us</div>
        <div class="detailDes">福利盒子是于 2019 年全新推出的，致力于为广大用户提供各类优秀福利app，涵盖了在市场上最受欢迎和最新的应用程序，目前每日活跃用户已超过一千万。</div>
      </div>
      <div class="left" style="text-align: right">
        <img class="img_style" src="../../../assets/pic/pic3.png" alt="" />
      </div>
    </div>
    <div class="bottom_box">
<!--      <div class="left">-->
<!--        <div class="bottom_des">友情链接：葫芦畅听音乐版</div>-->
<!--        <div class="bottom_des">北京臻鼎科技有限公司 | 地址 : 北京市海淀区紫金数码园4号楼8层807</div>-->
<!--        <div class="bottom_des">违法和不良信息举报：400-140-2108 | 京公网安备 11010802031927号</div>-->
<!--      </div>-->
<!--      <div class="left">-->
<!--        <div class="bottom_des">京ICP备18016437号-2 | 京网文【2021】3834-1005号 | 京ICP证 京B2-20181179号</div>-->
<!--        <div class="bottom_des">营业执照 | 出版物经营许可证 | 广播电视节目制作经营许可证</div>-->
<!--      </div>-->
      <div class="left" style="text-align: left">
        <div class="bottom_des">【如有疑问相关请联系此邮箱：zc975420@gmail.com】</div>
      </div>
    </div>
  </div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import { addLiquidId, getIP, isValidBrowser } from '@/utils/download'
import { queryDownloadInfo } from '@/api/home'
import { getChannelCode } from '@/utils/checkAgent'
import cjImg from '@/components/cjImg'
import QRCode from 'qrcodejs2'
export default {
  name: 'lu_novel_pc',
  components: { cjImg },
  data() {
    return {
      show: false,
      Interval: null,
      imgAddr: '',
      appInfo: '',
      url: '',
      aParams: [],
      loadFinished: false
    }
  },
  created() {
    this.IsPC()
    this.aParams = getChannelCode(this.$router.currentRoute.query.a)
    this.innitChannelInfo()
  },
  watch: {},
  methods: {
    async innitChannelInfo() {
      const md = new mobileDetect(u)
      let ver = ''
      if (md.os() == 'iOS') {
        //ios系统的处理
        ver = md.versionStr('iPhone')
      } else if (md.os() == 'AndroidOS') {
        //Android系统的处理
        ver = md.versionStr('Android')
      }
      var u = navigator.userAgent
      let deviceInfo = {}
      deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
      deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
      deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
      deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
      deviceInfo.isValidBrowser = isValidBrowser()
      deviceInfo.packageCode = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
      deviceInfo.invitationCode = this.aParams[2] || ''
      deviceInfo.appId = this.aParams[3] || process.env.VUE_APP_APP_ID
      window.deviceInfo = deviceInfo
      let that = this
      let clickId = that.$router.currentRoute.query.clickid
      console.log(that.$router.currentRoute, 'that.$router', window.location.hash.split('?')[1])
      getIP( async function (ip, area) {
        const params = {
          appId: deviceInfo.appId,
          channelCode: deviceInfo.packageCode,
          invitationCode: deviceInfo.invitationCode,
          clickId:clickId,
          deviceInfo: {
            ip: ip || '',
            // localIp: ,
            osType: md.os() == 'iOS' ? 1 : 0,
            osVersion: ver.replace(/\_/g, '.'),
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            deviceRatio: window.devicePixelRatio
          },
          systemType: 1
        }
        await addLiquidId(params)
        await that.getChannelInfo()
        // sessionReport({ ip })
      })
    },
    getChannelInfo() {
      let params = { data: { packageCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE } }
      console.log(this.aParams[1],'process.env.VUE_APP_CHANNEL_CODE')
      queryDownloadInfo(params)
        .then((res) => {
          console.log(res)
          this.appInfo = res.data
          this.imgAddr = res.imgAddr
          document.title = res.data.appName
          var link = document.querySelector("link[rel*='icon']")
          window.fetchAndDec(this.appInfo.logoUrl)
            .then((res) => {
              link.href= `data:image/jpg;base64,${res}`
            })
            .catch((err) => {})
          let origin = window.location.origin
          let pack = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
          let id = this.aParams[3] || process.env.VUE_APP_APP_ID
          let url = origin + '/downLoad?pack='+pack+'&id='+id
          if (window.deviceInfo.invitationCode) {
            url+='&iCode='+window.deviceInfo.invitationCode
          }
          if(this.$router.currentRoute.query.clickid){
            url+=`&clickid=${this.$router.currentRoute.query.clickid}`
          }
          this.url = url
          this.$nextTick(() => {
            new QRCode('qrcode', {
              render: 'canvas',
              width: '120',
              height: '120',
              text: url
            })
          })
          this.loadFinished = false
        })
        .catch(() => {})
    },
    IsPC() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      let info = navigator.userAgent
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      let isPhone = /mobile/i.test(info)
      //如果包含“Mobile”（是手机设备）则返回true
      if (isPhone) {
        const a = this.$router.currentRoute.query.a
        const clickid = this.$router.currentRoute.query.clickid
        const query = {}
        if (a) {
          query.a = a
        }
        if(clickid){
          query.clickid = clickid
        }
        this.$router.push({
          path: '/h5Page',
          query
        })
      }
    },
    toApp() {
      let origin = window.location.origin
      window.location = origin + '/'
    }
  }
}
</script>

<style scoped lang="scss">
.lu_novel_pc {
  width: 100%;
  height: 100%;
  background-image: url('../../..//assets/PC/bg.png');
  padding-top: 120px;
  .img_style {
    height: 600px;
    border-radius: 20px;
  }
  .button_top_right {
    height: auto;
    width: 400px;
    padding-top: 86px;
    .guankan {
      cursor: pointer;
      float: left;
      padding: 12px 40px;
      background-image: linear-gradient(123deg, #5a75e9 0%, #73bffa 100%);
      box-shadow: 0px 10px 17px 0px rgba(122, 172, 247, 0.6);
      border-radius: 12px;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 2px;
      text-align: center;
      font-weight: 500;
    }
  }
  .font_title {
    //opacity: 0.44;
    position: relative;
    font-family: PingFangSC-Medium;
    font-size: 5vw;
    color: #373178;
    font-weight: 900;
    letter-spacing: .3vw;
    z-index: 2000;
    white-space: nowrap;
  }
  .first_box {
    padding-top: 140px;
    height: 740px;
    // padding: 0 240px;
    // width: calc(100% - );
    display: flex;
    justify-content: space-between;
    .left {
      width: 40%;
      text-align: left;
      .top_icon {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img_box {
          width: 80px;
          height: 80px;
          border-radius: 4px;
        }
      }
      .desTitle {
        opacity: 0.44;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #8b85ca;
        font-weight: 400;
      }
      .qrcode_box {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 86px;
      }
      .ewm {
        width: 120px;
        height: 120px;
        border: 7px solid #7ab5fb;
        border-radius: 7px;
        padding: 2px;
      }
    }
    .right {
      width: 40%;
    }
  }
  .secondBox {
    display: flex;
    height: 560px;
    justify-content: space-between;
    .left {
      width: 40%;
      text-align: center;
    }
    .right {
      height: 100%;
      width: 40%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
      .titleDes {
        opacity: 0.44;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #8b85ca;
        font-weight: 400;
      }
      .detailDes {
        width: 387px;
        padding-top: 5px;
        opacity: 0.6;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #363178;
        font-weight: 400;
        text-align: left;
      }
    }
  }
  .thirdBox {
    display: flex;
    height: 560px;
    justify-content: space-between;
    .left {
      width: 40%;
      text-align: center;
    }
    .right {
      height: 100%;
      width: 40%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      .titleDes {
        opacity: 0.44;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #8b85ca;
        text-align: right;
        font-weight: 400;
      }
      .detailDes {
        width: 387px;
        padding-top: 5px;
        opacity: 0.6;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #363178;
        font-weight: 400;
        text-align: left;
      }
    }
  }
  .content {
    padding: 0 10%;
    width: 80%;
  }
  .bottom_box {
    background-image: linear-gradient(-45deg, #5778ea 0%, #73bffa 100%);
    width: 100%;
    //height: 220px;
    height: 120px;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left {
      width: fit-content;
      //height: 96px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 32px;
      font-weight: 400;
      .bottom_des{
        text-align: left;
      }
    }
  }
}
</style>
